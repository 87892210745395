import React, { useRef } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import PolicyBox from '../middle/PolicyBox';

const useStyles = makeStyles({
  section: {
    margin: `calc(1rem + 2vw) auto`,
  },
});

const PoliciesBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PolicyBox
            number="01."
            title="政治腐敗を浄化する政治改革"
            contents="企業団体献金と政策活動費の廃止、文書通信交通滞在費など議員特権の可視化と見直し。合わせて議員定数の大幅な削減・世襲制限を断行する。"
          />
        </Grid>
        <Grid item xs={12}>
          <PolicyBox
            number="02."
            title="世代間不公平を打破する社会保障の抜本改革"
            contents="現役世代に不利な制度は徹底的にすべて見直す。高齢者医療制度の適正化による現役世代の社会保険料負担軽減、年金は抜本改革して積立方式または最低所得保証制度を導入する。"
          />
        </Grid>
        <Grid item xs={12}>
          <PolicyBox
            number="03."
            title="可処分所得を倍増させる成長戦略・規制改革"
            contents="労働市場の流動化（金銭解決ルールの制度化等）、ライドシェアに象徴された旅客運送業を始めとする既存産業への参入障壁撤廃など、既得権にとらわれない大胆な規制改革で産業を成長させ、給料を飛躍的に伸ばす。"
          />
        </Grid>
        <Grid item xs={12}>
          <PolicyBox
            number="04."
            title="所得制限のない教育無償化と教育改革"
            contents="義務療育に加えて、幼児教育・高校において所得制限のない完全無償化を実現。また、大学・大学院などの改革と合わせて、教育の全過程の無償化及びそれを通じた教育の質の向上と教育機会の創出を目指す。"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default PoliciesBox;
