import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  firstViewBox: {
    width: `100%`,
    maxHeight: `30vh`,
    position: `relative`,
  },
  firstViewImageStyle: {
    width: `100%`,
    maxHeight: `30vh`,
  },
  firstViewTitle: {
    position: `absolute`,
    right: `10%`,
    top: `36%`,
    fontSize: `calc(1.5rem + 2vw)`,
  },
});

const FirstView: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.firstViewBox}>
      <StaticImage
        src="../../../images/PolicyFirstView.jpg"
        alt="main image"
        objectPosition="50% 50%"
        className={classes.firstViewImageStyle}
      />
      <Typography variant="h1" className={classes.firstViewTitle}>
        4つの政策
      </Typography>
    </div>
  );
};

export default FirstView;
