import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type PolicyBoxProps = {
  number: string;
  title: string;
  contents: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBox: {
      transform: `skew(-15deg)`,
      display: `flex`,
      alignItems: `center`,
    },
    number: {
      color: theme.palette.primary.main,
      fontSize: `1.5em`,
      margin: `0 .5em`,
    },
    contentsBox: {
      margin: `1rem auto`,
      marginLeft: `2.5rem`,
      position: `relative`,
      '&::after': {
        position: `absolute`,
        top: `0`,
        left: `-0.75rem`,
        zIndex: 2,
        content: `""`,
        width: `2px`,
        height: `100%`,
        backgroundColor: `#ccc`,
      },
    },
  }),
);

const PolicyBox: React.VFC<PolicyBoxProps> = (props) => {
  const { number, title, contents } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h2" className={classes.titleBox}>
        <span className={classes.number}>{number}</span>
        <span>{title}</span>
      </Typography>
      <div className={classes.contentsBox}>
        <Typography>{contents}</Typography>
      </div>
    </div>
  );
};

export default PolicyBox;
